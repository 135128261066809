import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowRight, faThumbsUp, faThumbsDown, faClock, faTasks, faChevronLeft, faSignInAlt, faInfoCircle, faCalendarAlt,
  faBook, faArrowLeft, faPaperclip, faChevronDown, faChevronRight, faPlusCircle, faHandPaper, faUserCircle, faPen,
  faMinus, faPlus, faHome, faSignOutAlt, faExclamationTriangle, faFilePen, faSchool, faUsers, faUserGraduate,
  faChalkboardTeacher, faUserTie, faFilm, faFileArrowDown, faPersonChalkboard, faDownload
  } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel, faFilePdf, faFileWord, faFilePowerpoint } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowRight, faThumbsUp, faThumbsDown, faClock, faTasks, faChevronLeft, faSignInAlt, faInfoCircle, faCalendarAlt,
  faBook, faArrowLeft, faPaperclip, faChevronDown, faChevronRight, faPlusCircle, faHandPaper, faUserCircle, faPen,
  faMinus, faPlus, faHome, faSignOutAlt, faExclamationTriangle, faFilePen, faSchool, faUsers, faUserGraduate,
  faChalkboardTeacher, faUserTie, faFilm, faFileArrowDown, faPersonChalkboard, faDownload
  )
library.add(faFileExcel, faFilePdf, faFileWord, faFilePowerpoint)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
// app.config.productionTip = false
// use(VueAxios, axios).

app.mixin({
  methods: {
    isManager: function () {
      return this.$store.state.userRoles.includes("Manager");
    },
    isStudent: function () {
      return this.$store.state.userRoles.includes("Student");
    },
    isTeacher: function () {
      return this.$store.state.userRoles.includes("Teacher");
    },
    isAdmin: function () {
      return this.$store.state.userRoles.includes("Admin");
    },
  }
});

app.use(store).use(router).use(ElementPlus).mount('#app')
