import axios from 'axios'

export default {
  namespaced: true,
  state: {
    classList: []
  },
  getters: {
    classList: state => state.classList
  },
  mutations: { // Synchronous
    setClassList(state, payload) { state.classList = payload }
  },
  actions: { // Asynchronous
    getClassList({ commit }) {
      axios.get("http://localhost:3000/classes").then((response) => {
        commit('setClassList', response.data);
      }).catch(function (error) { console.log('api error: ', error) });
    }
  }
}