import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import schedule from "@/store/schedule";
import classes from "@/store/classes";

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: window.localStorage,
		}),
	],
	state: {
		userRoles: [],
		authToken: "",
		userId: "",
	},
	getters: {
		getUserRoles: (state) => state.userRoles,
		getAuthToken: (state) => state.authToken,
		getUserId: (state) => state.userId,
	},
	mutations: {
		// Synchronous
		setUserRoles(state, payload) {
			state.userRoles = payload;
		},
		setAuthToken(state, payload) {
			state.authToken = payload;
		},
		setUserId(state, payload) {
			state.userId = payload;
		},
	},
	actions: {
		// Asynchronous
		storeUserRoles({ commit }, payload) {
			return commit("setUserRoles", payload);
		},
		storeAuthToken({ commit }, payload) {
			return commit("setAuthToken", payload);
		},
		storeUserId({ commit }, payload) {
			return commit("setUserId", payload);
		},
	},
	modules: {
		schedule: schedule,
		classes: classes,
	},
});
